import { Country, Environment } from "@/enums/Config";

export const Languages = ["es", "it", "pt", "en"];

export interface IConfig {
  MYRENTALS_URL: string;
  MYMORTGAGES_URL: string;
  MYREALESTATE_URL: string;
  MYHOUSFY_URL: string;
  WEBAPP_URL: string;
}

export interface ICountryConfig {
  local: IConfig;
  pre: IConfig;
  prod: IConfig;
}

export interface IMyRentalsConfig {
  country: Country;
  env: Environment;
  config: IConfig;
}
